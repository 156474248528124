import React from 'react';
import { withFeatureFlags } from 'components/context/withFeatureFlags';
import HelpSupportV2 from './HelpSupportV2';
import HelpSupport from './HelpSupport';

const HelpSupportContainer = (props) => {
  const { getFeatureFlags } = props;
  const useHelpSupportV2 = getFeatureFlags('FEATURE_HELP_SUPPORT_V2');

  if (useHelpSupportV2) {
    return <HelpSupportV2 {...props} />;
  }
  return <HelpSupport {...props} />;
};

export default withFeatureFlags(HelpSupportContainer);
