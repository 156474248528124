import { DefaultDocumentTitle } from 'constants/defaultConfig.js';
import { getCompetitionName } from './getCompetitionName.js';
import _ from 'lodash';

export function getPageTitle(navigationTitle, title) {
  if (navigationTitle === 'Internationals') {
    navigationTitle = getCompetitionName('internationals');
  }
  if (navigationTitle === 'Premier League') {
    navigationTitle = 'Premier League/EPL';
  }
  if (navigationTitle === "Women's Football") {
    navigationTitle = `Women's Super League/WSL`;
  }
  return navigationTitle ? `${navigationTitle} ${title}` : `${title}`;
}

export const getPageTitleFromPath = (path) => {
  const pathVariables = path.split('/');
  const competitionPath = _.get(pathVariables, '0', '');
  const competitionSubPath = _.get(pathVariables, '1', '');
  const competitionName =
    getCompetitionName(competitionPath) || competitionPath;
  return competitionName
    ? `${competitionName} ${competitionSubPath || ''}`
    : `${competitionSubPath || ''}`;
};

export const getPageTitleFromRootPath = (
  path,
  defaultTitle = DefaultDocumentTitle,
) => {
  let title = defaultTitle;
  switch (path) {
    case '/':
      title = `Latest Premier League & Football News | Highlights, Scores & More`;
      break;
    case '/login':
      title = 'Log in to Optus Sport';
      break;
    case '/teams':
      title = 'Football Teams';
      break;
    case '/helpsupport':
      title = 'Help & Support';
      break;
    case '/forgotpassword':
      title = 'Forgot Password';
      break;
    case '/changepassword':
      title = 'Change Password';
      break;
    case '/help':
      title = 'Submit Ticket';
      break;
    case '/unsupported':
      title = 'Unsupported Browser';
      break;
    case '/downloadapp':
      title = 'Download the app';
      break;
    case '/podcasts':
      title = 'Football Podcasts';
      break;
    case '/signup':
      title = `Sign-Up for a Free Optus Sport Account`;
      break;
    case '/campaign':
      title = 'Redeem your code';
      break;
    case '/register':
      title = 'Create your Optus Sport account';
      break;
    case '/fitness':
      title = 'Fitness';
      break;
    case '/checkout':
      title = 'Select or Change your Optus Sport plan';
      break;
    case '/usersettings':
      title = 'Your settings';
      break;
    case '/connect':
      title = 'Connect your device';
      break;
    case '/search':
      title = `Search Results${defaultTitle ? ` - ${defaultTitle}` : ``}`;
      break;
    case '/news':
      title = `Football News, Videos, Premier League, Women's Football Articles & Transfer Updates`;
      break;
    case '/epl':
      title = `Latest Premier League News | Highlights, Scores & More`;
      break;
    case '/womens':
      title = `Latest Women's Super League News | Highlights, Scores & More`;
      break;
    default:
      break;
  }

  return title;
};
