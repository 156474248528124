import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import HelpSupportModal from './ui-kit/HelpSupportModal';
import Button from './ui-kit/Button';
import LinkButton from './ui-kit/LinkButton';
import { dispatchAnalytic } from 'ducks/analytic';

const HelpSupportUI = (props) => {
  const { showModal, getFeatureFlags } = props;

  useEffect(() => {
    if (showModal) {
      // when the help support modal open
      dispatchAnalytic({ name: 'Support' });
    }
  }, [showModal]);

  const sendHelpSupportClickEvent = (source) => {
    dispatchAnalytic({
      name: 'Help & Support Clicked',
      option: {
        source,
      },
    });
  };

  const isLoggedIn = props.user.loggedIn;

  const shouldShowSection = (showWhenLoggedIn, showWhenLoggedOut) =>
    (isLoggedIn && showWhenLoggedIn) || (!isLoggedIn && showWhenLoggedOut);

  const config = getFeatureFlags('HELP_SUPPORT_CONFIG_V2');
  const {
    headingText = '',
    subText = '',
    faqSection = {},
    chatSection = {},
    reportIssueSection = {},
    socialsSection,
    activeIssuesSection,
  } = config;

  const getFaQSection = () => {
    const { title, faqLinks } = faqSection;

    return (
      <div className='mt-32 mb-16'>
        {title && <div className='text-base leading-lg'>{title}</div>}
        <div className='my-8 flex flex-col'>
          {faqLinks.map(
            ({ url, text, showWhenLoggedIn, showWhenLoggedOut }, index) => {
              return shouldShowSection(showWhenLoggedIn, showWhenLoggedOut) ? (
                <a
                  className='link-css leading-lg py-8'
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                  key={`faq_link_${index}`}
                >
                  {text}
                </a>
              ) : null;
            },
          )}
        </div>
      </div>
    );
  };

  const getChatSection = () => {
    const { showWhenLoggedIn, showWhenLoggedOut } = chatSection;

    if (shouldShowSection(showWhenLoggedIn, showWhenLoggedOut)) {
      const {
        titleWhenSocialsEnabled = '',
        titleWhenSocialsDisabled = '',
        chatBtn = {},
        supportHubBtn = {},
      } = chatSection;

      let chatBtnHtml = '';
      if (!isEmpty(chatBtn) && !isEmpty(chatBtn?.text)) {
        const {
          text,
          link,
          type,
          analytics: { source },
        } = chatBtn;
        chatBtnHtml = (
          <Button
            className={`w-full`}
            buttonClassName={`min-w-128 w-full sm:min-w-200 sm:max-w-292 mt-8`}
            text={text}
            type={type}
            handler={() => {
              props.closeModal();
              sendHelpSupportClickEvent(source);
              window.open(link, '_blank');
            }}
            width='w-full'
          />
        );
      }

      let supportHubBtnHtml = '';
      if (!isEmpty(supportHubBtn) && !isEmpty(supportHubBtn?.text)) {
        const {
          text,
          link,
          type,
          analytics: { source },
        } = supportHubBtn;
        supportHubBtnHtml = (
          <Button
            className={`w-full`}
            buttonClassName={`min-w-128 w-full sm:min-w-200 sm:max-w-292 sm:my-8`}
            text={text}
            type={type}
            handler={() => {
              props.closeModal();
              sendHelpSupportClickEvent(source);
              window.open(link, '_blank');
            }}
            width='w-full'
          />
        );
      }

      const {
        showWhenLoggedIn: socialShowWhenLoggedIn,
        showWhenLoggedOut: socialShowWhenLoggedOut,
      } = socialsSection;

      const isSocialSectionDisplayed = shouldShowSection(
        socialShowWhenLoggedIn,
        socialShowWhenLoggedOut,
      );

      return (
        <div className='flex mt-16 mb-24 flex-col items-start justify-between'>
          {(titleWhenSocialsEnabled || titleWhenSocialsDisabled) && (
            <div className='text-base text-white font-MarkProHeavy leading-lg mb-8'>
              {isSocialSectionDisplayed
                ? titleWhenSocialsEnabled
                : titleWhenSocialsDisabled}
            </div>
          )}
          <div className='flex flex-col sm:flex-row w-full gap-16 justify-between'>
            {chatBtnHtml}
            {supportHubBtnHtml}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const getReportIssueSection = () => {
    const {
      title = '',
      loginBtn: { type = 'secondary', link = '/login', text = '' },
      showWhenLoggedIn,
      showWhenLoggedOut,
    } = reportIssueSection;

    if (shouldShowSection(showWhenLoggedIn, showWhenLoggedOut)) {
      return (
        <div className='flex flex-col items-start justify-between'>
          <div className='text-base text-white font-MarkProHeavy leading-lg'>
            <div>{title}</div>
            {text && (
              <Button
                type={type}
                className={`w-full`}
                buttonClassName={`min-w-128 w-full sm:w-279 my-16`}
                text={text}
                textClassName={'font-MarkProHeavy'}
                handler={() => {
                  props.closeModal();
                  props.history.push(link);
                }}
              />
            )}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const getSocialsSection = () => {
    const {
      title = '',
      socials = [],
      showWhenLoggedIn,
      showWhenLoggedOut,
    } = socialsSection;

    if (shouldShowSection(showWhenLoggedIn, showWhenLoggedOut)) {
      return (
        <div className='bg-core-dark-grey rounded-xl px-16 py-8 mb-16'>
          <div className='text-base text-white font-MarkProHeavy leading-lg'>
            <div>{title}</div>
            <div className='flex flex-row justify-between xs:justify-evenly mt-8'>
              {socials.map((socialObj, index) => {
                const {
                  url = '',
                  cssClass = '',
                  icon = '',
                  text = '',
                  analytics: { source },
                } = socialObj || {};
                return (
                  <React.Fragment key={`social_${index}`}>
                    <a
                      href={url || ''}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={`flex flex-col items-center font-MarkPro text-white no-underline xxs:w-90 pt-16 pb-8 ${cssClass}`}
                      onClick={() => {
                        sendHelpSupportClickEvent(source);
                      }}
                    >
                      <img
                        src={icon}
                        alt={`${text} icon`}
                        height='34'
                        width='34'
                      ></img>
                      <div className='mt-16 text-white'>{text}</div>
                    </a>
                    {index < socials.length - 1 && (
                      <span className='min-h-full w-1 bg-white10'></span>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  const getActiveIssuesSection = () => {
    const { linkText, showWhenLoggedIn, showWhenLoggedOut } =
      activeIssuesSection;

    if (shouldShowSection(showWhenLoggedIn, showWhenLoggedOut)) {
      return (
        <LinkButton
          text={linkText}
          className='link-css text-base pb-20 underline block self-start'
          textClassName={'font-MarkPro'}
          handler={() => {
            props.closeModal();
            // Trigger bell icon hover
            props.showNotification();
            return false;
          }}
        />
      );
    }

    return <></>;
  };

  return (
    <HelpSupportModal
      open={props.showModal}
      onClose={() => props.closeModal()}
      type='helpsupport'
      newHome={props.newHome}
      className='md:h-auto-important md:max-h-full md:pb-16 md:min-h-687'
    >
      <div className='flex flex-col pt-48 xl:pt-32 help-support'>
        {headingText && (
          <h1 className='font-MarkProHeavy text-2xl xl:text-4xl text-white leading-none'>
            {headingText}
          </h1>
        )}
        {subText && (
          <div
            className='mt-40 text-base leading-lg'
            dangerouslySetInnerHTML={{ __html: subText }}
          ></div>
        )}
        {getFaQSection()}
        {getSocialsSection()}
        {getChatSection()}
        {getReportIssueSection()}
        {getActiveIssuesSection()}
      </div>
    </HelpSupportModal>
  );
};

export default withRouter(HelpSupportUI);
