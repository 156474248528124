import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { getCompetitionName } from './getCompetitionName';
import defaultConfig from 'constants/defaultConfig';

/**
 *
 * @param opta
 * @returns {string|null}
 */
export const getFixtureMetaDescription = (opta) => {
  const { competitionPath } = opta;
  const competitionName = getCompetitionName(competitionPath) || '';
  switch (competitionPath) {
    case 'epl':
    case 'uefa-champions-league':
      return `Find the upcoming ${competitionName} fixtures at Optus Sport, The World's Best Football. The exclusive home of every match, Live & On Demand.`;
    case 'internationals':
      return `Find the upcoming European International fixtures at Optus Sport, The World's Best Football. Catch the qualifiers for UEFA here at the Home of Football.`;
    case 'uefa-europa-league':
      return `Find all UEFA Europa League fixtures at Optus Sport, The World's Best Football. The exclusive home of every match, Live & On Demand.`;
    case 'womens':
      return `Find the upcoming ${competitionName} fixtures season at Optus Sport, Where Football Belongs.`;
    default:
      return `Find the upcoming ${competitionName} fixtures at Optus Sport, The World's Best Football.`;
  }
};

/**
 *
 * @param opta
 * @returns {string|null}
 */
export const getResultsMetaDescription = (opta = {}) => {
  const { competitionPath } = opta;
  const competitionName = getCompetitionName(competitionPath) || '';

  return `Get the latest ${competitionName} results, reviews & news. Highlights, mini-matches and on-demand. Subscribe now for $24.99 per month.`;
};

/**
 *
 * @param competitionPath
 * @returns {string|null}
 */
export const getSectionMetaDescription = (competitionPath) => {
  const competitionName =
    getCompetitionName(competitionPath) || 'all the action';
  switch (competitionPath) {
    case 'epl':
      return `Get the latest news, highlights, live scores & fixtures for the Premier League | Stream every match live & on-demand in Australia, on OPTUS SPORT`;
    case 'uefa-champions-league':
      return `Subscribe now for $24.99 to watch select UEFA Champions League match Live & On Demand on Optus Sport. Highlights and Mini-Matches for every fixture this season.`;
    case 'uefa-europa-league':
      return 'Subscribe now for $24.99 to watch select UEFA Europa League match Live & On Demand on Optus Sport. Catch the latest news from Europe on Scores on Sunday.';
    case 'internationals':
      return `Watch select European international fixture live on Optus Sport. Subscribe now for $24.99. The World's Best Football.`;
    case 'womens':
      return `Get the latest news, highlights, live scores & fixtures for the Women's Super League | Stream live & on-demand in Australia, on OPTUS SPORT`;
    case 'fifa-club-world-cup':
      return `Subscribe now for $24.99 to watch FIFA Club World Cup™ live on Optus Sport. The World's Best Football.`;
    case 'dfb-pokal':
      return `DFB Pokal games live and on demand. Replays, highlights and more...`;
    case 'fitness':
      return 'Stay in shape with OS Fitness. Premium workouts now available on Optus Sport.';
    case 'news':
      return `Enjoy comprehensive news, analysis, features and discussion from the Premier League, Women's Football, the Australian game and across the football planet from our team of experts here and abroad.`;
    case 'womens-world-cup-2023':
      return `FIFA Women's World Cup 2023™ games live and on demand. Replays, highlights and more...`;
    default:
      return `Subscribe now for $24.99 to watch ${competitionName} live on Optus Sport. The World's Best Football.`;
  }
};

/**
 *
 * @param categoryPath
 * @returns {string|null}
 */
const getCategory = (categoryPath) => {
  if (!categoryPath) {
    return null;
  }
  let categoryName = null;
  ['minimatches', 'news', 'classics', 'shows'].forEach((category) => {
    if (categoryPath.includes(category)) {
      categoryName = category;
    }
  });
  return categoryName;
};

/**
 *
 * @param competition
 * @param category
 * @returns {string|null}
 */
const getCompetitionCategory = (competition, category) => {
  const competitionName = getCompetitionName(competition);
  if (!category) {
    return null;
  }
  if (category === 'classics') {
    return `Watch classic matches from the ${competitionName} on Optus Sport. Thrillers all the way back to the start of the ${competitionName} era live here on The Home of Football.`;
  }
  return `Catch up on the latest ${category}, previews and reviews in the ${competitionName} on Optus Sport. Subscribe now for $24.99 per month.`;
};

/**
 *
 * @param category
 * @returns {string|null}
 */
const getHomeCategory = (category) => {
  if (!category) {
    return null;
  }
  return `Watch ${category} from the Premier League, Women's Super League and more. Catch 24 minute replays of every Premier League, Women's Super League game here. Only $24.99 per month.`;
};

/**
 *
 * @param pathname
 * @returns {string}
 */
export const getCategoryMetaDescription = (pathname) => {
  const splittedPath = pathname.split('/');
  const isHomeCategory = _.get(splittedPath, '[1]', null) === 'category';
  if (isHomeCategory) {
    const homeCategory = getCategory(_.get(splittedPath, '[2]', null));
    return getHomeCategory(homeCategory);
  }
  const competition = _.get(splittedPath, '[1]', null);
  const category = getCategory(_.get(splittedPath, '[3]', null));
  return getCompetitionCategory(competition, category);
};

/**
 *
 * @param opta
 * @returns {string|null}
 */
export const getTableMetaDescription = (opta) => {
  const competitionName = getCompetitionName(
    _.get(opta, 'competitionPath', null),
  );
  if (competitionName) {
    return `Catch up on the standings from ${competitionName} here. Updated live, this is where you get the latest table for ${competitionName}.`;
  }
  return null;
};

/**
 *
 * @param resultsOrFixtures
 * @param assetData
 * @returns {number|bigint|any|null}
 */
const getMatchFromResultsOrFixtures = (resultsOrFixtures = [], assetData) => {
  const optaSeasonId = _.get(assetData, 'optaSeasonId', null);
  const optaCompetitionId = _.get(assetData, 'optaCompetitionId', null);
  const optaMatchId = _.get(assetData, 'optaMatchId', null);
  if (optaSeasonId && optaCompetitionId && optaMatchId) {
    const data = resultsOrFixtures.find(
      (f) =>
        f.competitionId === parseInt(optaCompetitionId) &&
        f.season === optaSeasonId,
    );
    const matchDataRows = _.get(data, 'rows', []);
    return matchDataRows.find((row) => row.MatchID === optaMatchId);
  }
  return null;
};

/**
 *
 * @param assetData
 * @param props
 * @returns {string|*}
 */
const getMatchDate = (assetData, props) => {
  let matchDate =
    _.get(assetData, 'match.kickOffTime', null) ||
    _.get(assetData, 'broadcastStartTime', null) ||
    _.get(assetData, 'liveBroadcastTime', null);
  if (!matchDate) {
    const results = [..._.get(props, 'results', [])];
    const fixtures = [..._.get(props, 'fixtures', [])];
    matchDate =
      _.get(
        getMatchFromResultsOrFixtures(results, assetData),
        'MatchDateTime',
        null,
      ) ||
      _.get(
        getMatchFromResultsOrFixtures(fixtures, assetData),
        'MatchDateTime',
        null,
      );
  }
  if (matchDate) {
    return moment(
      matchDate.substr(-1) !== 'Z' ? `${matchDate}Z` : matchDate,
    ).format('DD-MM-YYYY');
  }
  return null;
};

/**
 *
 * @param assetData
 * @param props
 * @returns {{matchDate: *, awayTeam: *, matchDetailsReady: boolean, competitionName: *, homeTeam: *}|*}
 */
const getMatchDetailsFromAsset = (assetData = {}, props = {}) => {
  const { match = null, description = '' } = assetData;
  let matchDetailsAvailable = true;
  if (!match) {
    matchDetailsAvailable = false;
  }
  const homeTeam = _.get(match, 'homeTeam.officialName', 'Home Team');
  const awayTeam = _.get(match, 'awayTeam.officialName', 'Away Team');
  const competitionName = _.get(match, 'competition.title', 'Competition Name');
  let matchDate = getMatchDate(assetData, props);
  matchDate = matchDate ? ` on ${matchDate}` : '';
  return {
    homeTeam,
    awayTeam,
    competitionName,
    matchDate,
    description,
    matchDetailsAvailable,
  };
};

/**
 *
 * @param resultsOrFixtures
 * @param seasonId
 * @param competitionId
 * @returns {number|bigint|any}
 */
const getCompetitionDataFromResultsOrFixture = (
  resultsOrFixtures,
  seasonId,
  competitionId,
) =>
  resultsOrFixtures.find(
    (f) =>
      parseInt(f.competitionId, 10) === parseInt(competitionId, 10) &&
      parseInt(f.season, 10) === parseInt(seasonId, 10),
  );

/**
 *
 * @param props
 * @returns {{matchDate: *, awayTeam: *, competitionName: *, homeTeam: *}}
 */
const getMatchDetailsFromOpta = (props) => {
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  if (!_.isEmpty(query) && query.match && query.season && query.competition) {
    const { match, season, competition } = query;
    const { fixtures = [], results = [] } = props;
    const data =
      getCompetitionDataFromResultsOrFixture(fixtures, season, competition) ||
      getCompetitionDataFromResultsOrFixture(results, season, competition);
    const matchDataRows = _.get(data, 'rows', []);
    const matchData = matchDataRows.find(
      (row) => parseInt(row.MatchID, 10) === parseInt(match, 10),
    );

    if (matchData) {
      const {
        HomeTeamName = '',
        AwayTeamName = '',
        MatchDateTime = '',
        CompetitionID = '',
      } = matchData;

      const { getCompetitionTitle } = defaultConfig;
      const competitionName = getCompetitionTitle(CompetitionID);

      let matchDate = null;
      if (MatchDateTime) {
        matchDate = moment(
          MatchDateTime.substr(-1) !== 'Z'
            ? `${MatchDateTime}Z`
            : MatchDateTime,
        ).format('DD-MM-YYYY');
        matchDate = matchDate ? ` on ${matchDate}` : '';
      }

      return {
        homeTeam: HomeTeamName,
        awayTeam: AwayTeamName,
        competitionName,
        matchDate,
      };
    }
  }
};

/**
 *
 * @param matchDetails
 * @param assetType
 * @returns {string}
 */
const getPlayDescription = (matchDetails, assetType = '') => {
  const { homeTeam, awayTeam, competitionName, matchDate } = matchDetails;
  switch (assetType) {
    case 'linear-channel':
      return 'Watch around the clock coverage on our dedicated football channel, Optus Sport 1.';
    case 'highlights':
      return `Subscribe now for $24.99. All the best ${competitionName} moments on Optus Sport with ${homeTeam} v ${awayTeam} highlights.`;
    case 'minimatch':
      return `${homeTeam} v ${awayTeam}${matchDate} in 24 minutes, watch the Mini Match here. Subscribe for $24.99, get every mini match from ${competitionName} all season.`;
    default:
      return `Watch ${competitionName}, subscribe now for $24.99. Catch ${homeTeam} vs ${awayTeam}${matchDate} live & on-demand on Optus Sport.`;
  }
};

/**
 *
 * @param assetData
 * @param props
 * @returns {string|null}
 */
export const getPlayMetaDescription = (assetData, props) => {
  if (_.get(props, 'asset.readyData', false)) {
    let matchDetailsFromAsset;
    if (!_.isEmpty(assetData)) {
      const { assetTypeName = null } = assetData;
      matchDetailsFromAsset = getMatchDetailsFromAsset(assetData, props);

      if (
        matchDetailsFromAsset &&
        matchDetailsFromAsset.matchDetailsAvailable
      ) {
        return getPlayDescription(matchDetailsFromAsset, assetTypeName);
      }
    }

    const optaMatch = getMatchDetailsFromOpta(props);
    if (optaMatch) {
      return getPlayDescription(optaMatch);
    }

    return (matchDetailsFromAsset && matchDetailsFromAsset.description) || null;
  }

  return null;
};

export const getTeamProfileMetaDescription = (teamName) =>
  `Find the latest results, tables and fixtures for ${teamName} here. In depth stats, recent highlights plus more at the Home of Football.`;
